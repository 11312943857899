jQuery(document).ready(function($){

    $('.quantity__minus').click(function(event) {
        event.preventDefault();

        var sel = $(this).data('quantity');
        var $qty_el = $('#' + sel);

        if ($qty_el.length) {
            var cur_qty = parseInt($qty_el.val());
            var new_qty = Math.max(1, cur_qty - 1);
            $qty_el.val(new_qty);
            $qty_el.trigger('change');
        }
    });

    $('.quantity__plus').click(function(event) {
        event.preventDefault();

        var sel = $(this).data('quantity');
        var $qty_el = $('#' + sel);

        if ($qty_el.length) {
            var cur_qty = parseInt($qty_el.val());
            var new_qty = cur_qty + 1;
            var max_qty = $qty_el.attr('max');

            if (max_qty) {
                new_qty = Math.min(new_qty, max_qty);
            }

            $qty_el.val(new_qty);
            $qty_el.trigger('change');
        }
    });

    $('form.checkout').on('click', '.wc_payment_method .input-radio', function() {
        $('form.checkout .wc_payment_method').each(function () {
            var has_checked = $(this).find(".input-radio:checked").length;

            if (has_checked) {
                $(this).addClass("active");
            } else {
                $(this).removeClass("active");
            }
        });
    });

    $('body').on('wc_cart_button_updated', function(event, $button) {
        $button.text(tense_vars.added_to_cart_caption);
    });

    $('body').on('added_to_cart', function(event) {
        $('.woocommerce-notice-wrap').html('<p class="woocommerce-info">' + tense_vars.added_to_cart_notice + '</p>');

        $('html, body').animate({
            scrollTop: $('.woocommerce-notice-wrap').offset().top
        }, 800);
        return false;
    });


});
